@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

body {
    font-family: "Comfortaa", sans-serif;
}

.bg-primary, .primary {
    color: #8C53FF !important;
}

.bg-purple {
    background-color: #8C53FF !important;
}

.btn {
    padding: 10px 20px !important;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700 !important;
    color: white;
}
.btn-purple {
    background-color: #8C53FF;
}

.home .btn-purple {
    border: 1px solid #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.btn-white {
    background-color: #ffffff;
    color: #8C53FF;
}

.btn-white:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    color: #8C53FF;
}

.btn-purple:hover {
    background-color: rgb(109, 58, 212);
    color: white;
}

.form-check.form-switch {
    position: relative;
    padding: 0;
}

.form-check.form-switch label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-align: center;
}

.form-check.form-switch label {
    color: #ffffff;
}

.form-check.form-switch .form-check-input {
    width: 100px;
    height: 26px;
    margin: 0;
    background-color: #FFBD59;
    border-color: #FFBD59;
}

.form-check.form-switch.switch2 .form-check-input {
    width: 115px;
}

.form-check.form-switch.switch2 .text-online {
    width: 65px;
}

.form-check.form-switch .text-online {
    opacity: 0;
    background-color: #8C53FF;
}

.form-check.form-switch .form-check-input:checked {
    background-color: #8C53FF;
    border-color: #8C53FF;
}

.form-check.form-switch #onlineOfflineButton:checked~.text-online {
    opacity: 1;
}

.form-check.form-switch #onlinePaymnetButton:checked~.text-online {
    opacity: 1;
}

header.home {
    background-color: #8C53FF;
}
header .navbar .navbar-nav select {
    color: #ffffff;
    font-weight: 600;
    background-color: transparent;
}

header .navbar .navbar-nav select option {
    color: black;
}

#heroCarousel .carousel-inner .carousel-item {
    /* background-color: #8C53FF;
    height: calc(100vh - 135px); */
}

#heroCarousel .carousel-inner .carousel-item::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    /* background-image: url('..//images/images/hero-pattern.svg'); */
    width: 100%;
    height: 110px;
    z-index: 0;
}

#heroCarousel .carousel-inner .carousel-item .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 230px;
    position: relative;
    z-index: 1;
}

#heroCarousel .carousel-inner .carousel-item .content .content-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    color: white;
    max-width: 575px;
}

#heroCarousel .carousel-inner .carousel-item .content .content-text h2 {
    font-size: 44px;
    color: #FFBD59;
    line-height: 60px;
}

#heroCarousel .carousel-inner .carousel-item .content .content-text h2 span {
    font-size: 74px;
    color: white;
}

#heroCarousel .carousel-inner .carousel-item .content .content-text p {
    font-size: 24px;
    margin-bottom: 0;
}

#heroCarousel .carousel-inner .carousel-item .content .content-image img {
    max-width: 420px;
}

#heroCarousel .carousel-indicators {
    bottom: 30px;
}

#heroCarousel .carousel-indicators button {
    border: none;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #553595;
}

#heroCarousel .carousel-indicators button.active {
    background-color: #ffffff;
}

.unlock-city {
    padding: 80px 0;
    position: relative;
}

.unlock-city::before {
    content: '';
    width: 100px;
    height: 230px;
    background-image: url('..//images/images/left-graphic.png');
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.unlock-city::after {
    content: '';
    width: 120px;
    height: 221px;
    background-image: url('..//images/images/right-graphic.png');
    background-repeat: no-repeat;
    position: absolute;
    bottom: 70px;
    right: 0;
}

.unlock-city h2 {
    font-size: 56px;
    margin-bottom: 60px;
}

.unlock-city .card {
    background-color: #F9F6FF;
    border-radius: 12px;
    border: none;
    padding: 33px 21px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.unlock-city .card img {
    max-width: 60px;
}

.unlock-city .card h4 {
    font-size: 24px;
    font-weight: 600;
}

.unlock-city .card a {
    color: #8C53FF;
    text-decoration: none;
    font-weight: 700;
}

.download-app {
    background-color: #8C53FF;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    border-radius: 10px;
}

.download-app .download-app-content {
    padding: 0 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.download-app .download-app-content h3 {
    font-size: 40px;
    color: #FFBD59;
}

.download-app .download-app-content h3 span {
    color: white;
}

.download-app .download-app-content p {
    color: white;
}

.footer {
    padding: 20px 0;
}

.footer .footer-nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
}

.footer .footer-nav ul li {
    position: relative;
}

.footer .footer-nav ul li::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #D9D9D9;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.footer .footer-nav ul li a {
    text-decoration: none;
    color: #000000;
    padding: 15px 22px 15px 15px;
    font-weight: 600;
    display: flex;
}

.footer .footer-nav ul li:last-child:after {
    display: none;
}

.footer .footer-nav ul li span {
    padding-left: 20px;    
}

.footer .footer-social ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 15px;
} 

.events .event-controls {
    display: flex;
    align-items: center;
    gap: 20px;
}

.events ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0 0 20px 0;
    padding: 0;
}

.events a {
    text-decoration: none;
}

.events .card {
    background-color: #F9F6FF;
    border: none;
}

.business-onboarding {
    padding: 30px 0px;
}

.business-onboarding .stepper-form {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    gap: 15px;
}

.business-onboarding .stepper-form li {
    width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 17px;
}

.business-onboarding .stepper-form li .progress {
    width: 100%;
    height: 9px;
    background-color: #F5F0FF;
}

.business-onboarding .stepper-form li .stepper-item {
    display: flex;
    gap: 10px;
}

.business-onboarding .stepper-form li .stepper-item span {
    background-color: #8C53FF;
    width: 19px;
    height: 19px;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 13px;
    font-weight: 700;
}

.business-onboarding .business-stepper-form {
    margin-top: 50px;
} 

.business-onboarding .business-stepper-form .form-control {
    height: 56px;
    border-color: #C8C8C8;
}

.business-onboarding .business-stepper-form .all-set {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #6EB641;
}

.custom-file {
    background-color: #F8F8F8;
    border-radius: 6px;
    padding: 7px;
}

.custom-file .custom-file-content {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px dashed #8C53FF;
    border-radius: 6px;
    height: 56px;
    padding: 10px 15px;
}

.custom-file .custom-file-content span {
    color: #C5C5C5;
}

.custom-file .custom_file_uploader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.event-cover {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    max-height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.event-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.62);
}

.event-cover .event-cover-content {
    position: absolute;
    top: 50%;
    left: 85px;
    transform: translateY(-50%);
    color: white;
}

.event-cover .event-cover-content .back-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    text-decoration: none;
}

.event-cover .event-cover-content .location {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #FFC300;
    text-decoration: none;
}

.event-details .social-media ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 10px;
}

.event-details .date-time {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    padding: 20px;
    background-color: #F6F6F6;
    border-radius: 6px;
}

.event-details .date-time .date-time-inner {
    display: flex;
    flex-direction: column;
}

.event-details .date-time .date-time-inner .date {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
}

.event-details .date-time .date-time-inner .time {
    font-size: 18px;
    font-weight: 600;
}

.event-details .price {
    background-color: #F9F6FF;
    padding: 30px;
    border-radius: 6px;
}

.event-details .price .date-time {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.event-details .price .date-time h4 {
    font-size: 18px;
}

.event-details .price .date-time h3 {
    font-size: 20px;
}

.flag-input {
    display: flex;
    align-items: center;
    gap: 10px;
}

.flag-input input {
    border: none;
    width: 100%;
    height: 100%;
}

.flag-input input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.about {
    height: 250px;
    background-color: #8C53FF;
    position: relative;
    padding-top: 30px;
}

.about::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url('..//images/images/hero-pattern.svg');
    width: 100%;
    height: 110px;
}

.about-content ul {
    padding: 0;
    margin-top: 30px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.about-content ul li {
    position: relative;
    padding-left: 40px;
    font-weight: 700;
}

.about-content ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    background-image: url('..//images/images/check-purple.svg');
    width: 28px;
    height: 28px;
}

.about-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F6F6;
    padding: 40px;
    border-radius: 10px;
}

.privacy-policy {
    padding: 50px 0;
}

.privacy-policy h3 {
    font-size: 22px;
    margin-top: 20px;
}

.privacy-policy p {
    font-size: 16px;
    margin-bottom: 1rem;
}

.privacy-policy ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.select2-results__options {
    max-height: 300px;
    overflow-y: auto;
}

.select2-container {
    border: 1px solid #ccc;
    border-radius: 6px;
    height: 56px;
    padding: 16px 7px;
}


/* Responsive Code */
@media screen and (max-width: 1199.99px) {
    #heroCarousel .carousel-inner .carousel-item .content {
        gap: 50px;
        max-width: 100%;
    }
}


@media screen and (max-width: 991px) {
    header .btn {
        background-color: transparent;
        /* color: #8C53FF; */
    }
    #heroCarousel .carousel-inner .carousel-item {
        height: calc(90vh - 106px);
    }
    #heroCarousel .carousel-inner .carousel-item .content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 100px !important;
    }

    #heroCarousel .carousel-inner .carousel-item .content .content-text {
        align-items: center;
        gap: 0;
        text-align: center;
    }

    #heroCarousel .carousel-inner .carousel-item .content .content-text h2 {
        font-size: 24px;
        text-align: center;
    }

    #heroCarousel .carousel-inner .carousel-item .content .content-text p {
        font-size: 18px;
    }

    #heroCarousel .carousel-inner .carousel-item .content .content-text h2 span {
        font-size: 34px;
    }

    header .navbar .navbar-nav {
        flex-direction: row;
        gap: 10px !important;
    }
    header:not(.home) .btn {
        width: auto;
        padding: 0 !important;
        border: none;
        box-shadow: none;
        font-size: 13px;
        text-decoration: none;
        border-bottom: 1px solid;
        border-radius: 0;
        padding: 10px 0 !important;
    }

    .unlock-city {
        padding: 50px 0;
    }

    .unlock-city h2 {
        font-size: 34px;
        margin-bottom: 20px;
    }

    .download-app .download-app-content {
        padding: 20px;
    }

    .download-app .download-app-content h3 {
        font-size: 24px;
    }

    .footer {
        padding: 30px 0;
    }

    .footer .footer-nav ul li a {
        padding: 5px 22px 5px 15px;
    }

    .footer .footer-social ul {
        padding-left: 15px;
        padding-top: 15px;
    }

    .unlock-city .card img {
        max-width: 40px;
    }

    .other .btn-purple {
        border: none;
        color: white;
        box-shadow: none;
        font-size: 13px;
        text-decoration: none;
        border-bottom: 1px solid;
        border-radius: 0;
        padding: 10px 0 !important;
    }
    
    /*.mobile-btn {*/
    /*    padding: 10px 20px !important;*/
    /*    font-weight: 700 !important;*/
    /*    color: white;*/
    /*    background-color: #8C53FF;*/
    /*    text-decoration: none;*/
    /*    border-radius: 8px;*/
    /*}*/
    
    /*.home .mobile-btn {*/
    /*    background-color: #ffffff;*/
    /*    color: #8C53FF;*/
    /*}*/

    .event-cover .event-cover-content {
        left: 0;
        padding: 0 20px;
    }
    .mobile-border {
        border-top: 1px solid #fff;
        padding-top: 20px;
    }
    
    .navbar-brand img {
        max-width: 80px;
    }
}

/* HTML: <div class="loader"></div> */
.loader_new {
    width: 50px;
    --b: 8px; 
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 1px;
    background: conic-gradient(#0000 10%,#f03355) content-box;
    -webkit-mask:
      repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
      radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
    -webkit-mask-composite: destination-in;
            mask-composite: intersect;
    animation:l4 1s infinite steps(10);
  }
  @keyframes l4 {to{transform: rotate(1turn)}}