.inapp-upcoming-event {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f4f4f4;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
.inapp-upcoming-event .event-card {
    background: #fff;
    width: 90%;
    /* max-width: 400px; */
    margin: 15px 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.inapp-upcoming-event .event-image {
    width: 100%;
    height: 300px;
}
.inapp-upcoming-event .event-info {
    padding: 15px;
}
.inapp-upcoming-event .event-info h2 {
    font-size: 18px;
    margin: 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.inapp-upcoming-event .event-date {
    font-size: 14px;
    color: gray;
}
.inapp-upcoming-event .tags {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}
.inapp-upcoming-event .tag {
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
}
.inapp-upcoming-event .learning { background-color: #fe9635; color: white; }
.inapp-upcoming-event .offline { background-color: #8d53ff; color: white; }

@media (min-width: 768px) {
  
    .inapp-upcoming-event  .event-card {
        max-width: 600px;
    }
    .inapp-upcoming-event  .event-info h2 {
        font-size: 22px;
    }
    .inapp-upcoming-event  .event-date {
        font-size: 16px;
    }
    .inapp-upcoming-event   .tag {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .inapp-upcoming-event  {
        flex-direction: column;
    }
}

/* details page  */


.upcoming-details-page-div {
    padding: 20px;
    margin-bottom: 50px;
    background-color: #8c53ff;
  }
  
  .upcoming-details-page-div .main-details-page-content {
    background-color: #ffffff;
    margin: auto;
    border-radius: 30px;
    /* overflow: hidden; */
  }
  
  .upcoming-details-page-div .main-details-page-content .head_details {
    background-color: #c4aef0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    color: #ffffff;
    border-radius: 30px 30px 0px 0px;
  }
  
  .upcoming-details-page-div .main-details-page-content .head_details img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 5px solid #ffffff;
  }
  
  .upcoming-details-page-div  .main-details-page-content .head_details span {
    font-weight: 900;
    font-size: 32px;
    line-height: 42px;
  }
  
  .upcoming-details-page-div  .head_text {
    font-weight: 900;
  }
  
  .upcoming-details-page-div   .main-details-page-content .description_details {
    padding: 20px;
    border-bottom: #ddd solid 1px;
  }
  
  .upcoming-details-page-div  .description_details .social-media ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 10px;
  }
  
  .upcoming-details-page-div .price_details_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  
  }
  
  .upcoming-details-page-div .price_details {
    background-color: #ffffff;
    position: sticky;
    bottom: 0;
    border-radius: 0px 0px 30px 30px;
    padding: 20px;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, .1);
  }
  
  .upcoming-details-page-div .main-details-page-content .time_details {
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    border-bottom: #ddd solid 1px;
  }
  
  .upcoming-details-page-div .time_details_001 {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
  }
  
  /* Mobile styles: Apply flex-direction: column on small screens */
  @media (max-width: 768px) {
   
  
    .upcoming-details-page-div  .main-details-page-content .head_details img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      border: 4px solid #ffffff;
    }
  
    .upcoming-details-page-div  .main-details-page-content .head_details span {
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
    }
  }
  
  .upcoming-details-page-div  .date-container {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 5px 10px;
  }
  
  .upcoming-details-page-div .date-box {
    text-align: center;
    margin-right: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .upcoming-details-page-div .date-box .month {
    font-size: 12px;
    font-weight: bold;
    color: #333;
    padding: 2px 8px;
    background-color: #d7d7d7;
  }
  
  .upcoming-details-page-div .date-box .day {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .upcoming-details-page-div .date-details {
    display: flex;
    flex-direction: column;
  }
  
  .upcoming-details-page-div  .date-details .day-date {
    font-size: 14px;
    font-weight: bold;
  }
  
  .upcoming-details-page-div .date-details .time {
    font-size: 12px;
    color: #666;
  }
  
  
  .upcoming-details-page-div  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .upcoming-details-page-div .data_not_found {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .upcoming-details-page-div  .data_not_found p {
    font-size: 30px;
    font-weight: 600;
    color: #adacac;
  }
  
  .upcoming-details-page-div  .css-v7duua:hover {
    color: #000000 !important;
    background-color: hsl(0, 0%, 90%) !important;
  }
  
  .upcoming-details-page-div  .css-b62m3t-container,
  .css-13cymwt-control,
  .css-t3ipsp-control {
    height: 100% !important;
    min-height: 56px !important;
    border-color: #C8C8C8 !important;
  }
  
  .upcoming-details-page-div   .sub_category_div {
    min-height: 56px !important;
    margin-top: 1rem;
  }