
  /* FAQ Page */
  .faqs-page {
    padding: 20px 20px;
    /* background: #f4f4f4; */
  }
  
  .page-title {
    font-size: 40px;
    margin-bottom: 10px;
    color: #8254E3;
  }
  
  .page-subtitle {
    font-size: 16px;
    color: #555;
    margin-bottom: 40px;
  }
  
  /* FAQ Container */
  .faqs-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.3s;
  }
  
  .faq-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background: #8254E3;
    color: white;
    font-size: 12px;
  }
  
  .faq-icon {
    margin-left: 20px;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .faq-answer {
    padding: 20px;
    background: #f9f9f9;
    color: #555;
    font-size: 1rem;
  }
  
  /* Active FAQ Styling */
  .faq-item.active .faq-question {
    background: #c4aef0;
  }
  
  .faq-item.active .faq-answer {
    animation: slideDown 0.3s ease;
  }
  
  /* Slide Down Animation */
  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 200px;
      opacity: 1;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .faqs-container {
      padding: 0 10px;
    }
  
    .faq-question {
      font-size: 12px;
    }
  
    .faq-answer {
      font-size: 0.9rem;
    }
  }
  