.edit-event-page {
    font-family: Arial, sans-serif;
    /* padding: 20px; */
}

.edit-event-page .stepper-form {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    gap: 15px;
}

.edit-event-page .stepper-form li {
    width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 17px;
}

.edit-event-page .stepper-form li .progress {
    width: 100%;
    height: 9px;
    background-color: #F5F0FF;
}

.edit-event-page .stepper-form li .stepper-item {
    display: flex;
    gap: 10px;
}

.edit-event-page .stepper-form li .stepper-item span {
    background-color: #8C53FF;
    width: 19px;
    height: 19px;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 13px;
    font-weight: 700;
}

/* notificaton */
.edit-event-page .notification-card {
    border-radius: 15px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 5px 5px;
    background-color: #ffdd59;
  border: solid 1px #8c53ff;
}

.edit-event-page .notification-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    /* border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; */
}

.edit-event-page .notification-content {
    padding: 10px;
    flex: 1;
    
}
.edit-event-page .notification-desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;

}

.edit-event-page .notification-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.edit-event-page .list-card {
    border-radius: 15px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px;
    justify-content: space-between;
    gap: 10px;
    
  border: solid 1px #8c53ff;
}
.edit-event-page .list-title {
    font-weight: bold;
    /* margin-bottom: 5px; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edit-event-page .notification-date {
    font-size: 12px;
    color: #888;
}

.edit-event-page .carousel-inner {
    /* max-width: 400px; */
    margin: auto;
}
.edit-event-page #notificationCarousel {
    margin-bottom: 30px;
}

.edit-event-page .carousel-indicators {
    margin: 0;
    bottom: -25px;
}

.edit-event-page .carousel-indicators button {
    background-color: #8c53ff;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.edit-event-page .header {
    background-color: #8254E3;
    color: white;
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.edit-event-page .menu {
    padding: 15px;
}
.edit-event-page .menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}
.edit-event-page .menu-item i {
    font-size: 15px;
    margin-right: 10px;
}

.no-data-app{
    color:#ddd;
    font-weight: 900;
    font-size: 24px;
    text-align: center;
}