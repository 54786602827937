/* Mobile App CSS */
.dashboard-listing .dashboard-listing-items {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.dashboard-listing .dashboard-listing-items .dashboard-listing-item {
    background-color: #F6F1FF;
    border: 1px solid #E3D4FF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    gap: 15px;
    text-decoration: none;
}

.dashboard-listing .dashboard-listing-items .dashboard-listing-item span {
    font-size: 16px;
    font-weight: 700;
    color: #383838;
}

.empty-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.empty-dashboard .no-listing-found {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 50px 0;
}
.empty-dashboard .empty-list-btns {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.empty-dashboard .empty-list-btns .btn-filled {
    background-color: #8C53FF;
    color: #ffffff;
    padding: 15px;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    text-align: center;
}

.empty-dashboard .empty-list-btns .btn-outlined {
    border: 1px solid #8C53FF;
    background-color: #fff;
    color: #8C53FF;
    padding: 15px;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    text-align: center;
}

.event-details.mobile {
    padding-top: 20px;
}

.event-details.mobile .enrolled {
    margin: 10px 0 20px;
    display: flex;
    font-weight: 700;
}

.event-details.mobile .title h1 {
    font-size: 24px;
    color: #8C53FF;
    margin-bottom: 0;
}

.event-details.mobile .title span {
    color: #FFBD59;
    font-weight: 700;
    text-transform: capitalize
}

.event-details.mobile .event-price {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.event-details.mobile .event-price h2 {
    margin-bottom: 0;
    color: #8C53FF;
    font-weight: 600;
    font-size: 20px;
}

.event-details.mobile .date-time .date-time-inner .date {
    font-size: 16px;
}

.event-details.mobile .date-time .date-time-inner .time {
    font-size: 14px;
}

.event-details.mobile .event-price span {
    font-size: 10px;
    font-weight: 600;
}

.chat-discussion {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0 20px;
}

.chat-discussion .chat-discussion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-discussion .chat-discussion-header h2 {
    font-size: 16px;
    font-weight: 700;
}

.chat-discussion .chat-discussion-header .report {
    display: flex;
    align-items: center;
    gap: 3px;
    text-decoration: none;
}

.chat-discussion .chat-discussion-header .report span {
    color: #FF1F1F;
}

.chat-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* max-height: 620px; */
    /* overflow-y: auto; */
    padding-bottom: 0px;
    padding: 10px;
    margin-bottom: 100px;

}

.chat-items .chat-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-items .chat-item-header span {
    color: #999999;
    font-size: 14px;
}

.chat-items .chat-item-header h5 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.chat-items .chat-body p {
    margin: 20px 0 0;
}

.chat-items .chat-item {
    background-color: #ffffff;
    border: 1px solid #F2F2F2;
    padding: 15px;
    border-radius: 10px;
}

.chat-message {
    /* position: sticky; */
  bottom: 0;
    position: fixed;
    /* bottom: 0; */
    /* left: 0; */
    background-color: #ffffff;
    padding: 10px 20px;
}

.chat-message .chat-message-div {
    display: flex;
    gap: 10px;
}

.chat-message .chat-message-div .send-btn {
    background-color: #8C53FF;
    padding: 13px 19px;
    border-radius: 4px;
    border: none;
}

.chat-message .form-check .form-check-label {
    font-size: 10px;
}
 
.refresh-img{
    margin-right: 20px;
    cursor: pointer;
}