.loading_main_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 50px 0px;
}

.row {
  margin: 0px !important;
  padding: 0px !important
}

.qr2,
.qr3,
.qr1 {
  font-weight: 600;
}

.qr1 {
  color: #893fd5;
}

.qr2 {
  color: #4873ff;
}

.qr3 {
  color: #e73e75;
}

/* .event-cover{
      min-height: 200px;
      max-height: 600px;
  }
  .event-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  } */
.loader_base {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-right: 16px solid green;
  border-bottom: 16px solid red;
  border-left: 16px solid pink;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.main-details-page-div {
  padding: 20px;
  margin-bottom: 50px;
  /* background-color: #8c53ff; */
}

.main-details-page-content {
  background-color: #ffffff;
  margin: auto;
  border-radius: 30px;
  border: solid 1px #8c53ff;
  padding: 0;
  margin-bottom: 20px;
  /* overflow: hidden; */
}

.main-details-page-content .head_details {
  background-color: #c4aef0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  color: #ffffff;
  border-radius: 30px 30px 0px 0px;
}

.main-details-page-content .head_details img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 5px solid #ffffff;
}

.main-details-page-content .head_details span {
  font-weight: 900;
  font-size: 32px;
  line-height: 42px;
}

.head_text {
  font-weight: 900;
}

.main-details-page-content .description_details {
  padding: 20px;
  border-bottom: #ddd solid 1px;
}

.description_details .social-media ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  align-items: center;
}

.price_details_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.price_details {
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  border-radius: 0px 0px 30px 30px;
  padding: 20px;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, .1);
  margin-left: 1px;
}

.main-details-page-content .time_details {
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  border-bottom: #ddd solid 1px;
}

.time_details_001 {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

/* Mobile styles: Apply flex-direction: column on small screens */
@media (max-width: 768px) {
  .time_details {
    /* flex-direction: column;
    align-items: flex-start; */
  }

  .main-details-page-content .head_details img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 4px solid #ffffff;
  }

  .main-details-page-content .head_details span {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }
}

.date-container {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 5px 10px;
}

.date-box {
  text-align: center;
  margin-right: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.date-box .month {
  font-size: 12px;
  font-weight: bold;
  color: #333;
  padding: 2px 8px;
  background-color: #d7d7d7;
}

.date-box .day {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.date-details {
  display: flex;
  flex-direction: column;
}

.date-details .day-date {
  font-size: 14px;
  font-weight: bold;
}

.date-details .time {
  font-size: 12px;
  color: #666;
}


.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.data_not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.data_not_found p {
  font-size: 30px;
  font-weight: 600;
  color: #adacac;
}

.css-v7duua:hover {
  color: #000000 !important;
  background-color: hsl(0, 0%, 90%) !important;
}

.css-b62m3t-container,
.css-13cymwt-control,
.css-t3ipsp-control {
  height: 100% !important;
  min-height: 56px !important;
  border-color: #C8C8C8 !important;
}

.sub_category_div {
  min-height: 56px !important;
  margin-top: 1rem;
}

.sub_category_div div {
  /* height: 100% !important; */
  /* min-height: 56px !important; */
  /* border-color: #C8C8C8 !important; */
}

.sub_category_div div:hover,
div:active,
div:focus,
input:focus {
  /* outline: 0 !important; */
  /* height: 100% !important; */
  /* min-height: 56px !important; */
  /* border-color: #C8C8C8 !important;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25) !important; */
}

.css-t3ipsp-control:hover,
.css-t3ipsp-control:active,
.css-t3ipsp-control:focus {
  outline: 0 !important;
  min-height: 56px !important;
  border-color: #C8C8C8 !important;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25) !important;
}

/* Default - Hide both initially */
.mobile-only,
.desktop-only {
  display: none;
}

/* Show for mobile screens (max width 768px) */

/* Show for desktop screens (min width 769px) */
@media (min-width: 769px) {
  .desktop-only {
    display: block;
  }
}

.payment-failed {
  margin-top: 50px;
  /* position: fixed;
  top: 0;
  left: 0; */
  /* width: 100%;
  height: 100%; */
  /* background-color: rgba(0, 0, 0, 0.6); */
  /* display: none; */
  /* align-items: center;
  justify-content: center; */
}

/* Modal Box */
.payment-failed div {
  background: white;
  padding: 20px 30px;
  /* border-radius: 10px; */
  text-align: center;
  width: 100%;
  /* max-width: 400px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* animation: fadeIn 0.4s; */
}

.payment-failed h2 {
  font-size: 24px;
  color: #d9534f;
}

.payment-failed p {
  margin-top: 10px;
  color: #555;
}

.home-page-hero {
  position: relative;
  margin-bottom: 30px;
}

.home-page-hero img {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.home-page-hero .hero-content {
  position: absolute;
  bottom: 25%;
  padding: 50px;
  margin: auto;
}

.home-page-hero .hero-content p {
  color: #fff;
  font-weight: 900;
  font-size: 48px;
}

.div1 {
  margin-bottom: 20px;
}

.yellow_button {
  border: none;
  border-radius: 30px;
  padding: 4px 20px;
  background-color: #fcb900;
  color: #fff;
  margin-top: 20px;
}

.black_button {
  border: none;
  border-radius: 30px;
  padding: 4px 20px;
  background-color: #000000;
  border: solid #fff 1px;
  color: #fff;
  margin-top: 20px;
}
.close-btn{
  order: none;
    background-color: #ffc107;
    color: #ffffff;
    padding: 15px 20px;
    font-weight: 900;
    border-radius: 20px;
}
.home-page-div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.home-page-div div {
  border-radius: 10px;
  padding: 15px 10px;
  text-align: center;
}

.home-page-div p {
  margin: 0;
}

.home-page-div button {
  margin-left: 20px;
  margin-right: 20px;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #ff6900;
  color: #fff;
  font-weight: 900;
}

.yellow_button:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.home-image-div button {
  border: solid 2px;
}


.home-image-div {
  position: relative;
  /* height: 350px; */
  height: 100%;
  padding: 20px;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.home-image-div p {
 font-size: 33px;
 font-weight: 900;
 text-align: center;
 }

.home-image-div div {
  /* position: absolute; */
  /* bottom: 20px;
  left: 0;
  display: flex;
  align-items: center;
  right: 0;
  justify-content: center;
  margin: auto; */
}

.home-image-div img {
  width: 100%;
  object-fit: cover;
  height: 350px;
}

.box_shadow {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3),
    -5px -5px 15px rgba(255, 255, 255, 0.9),
    inset 2px 2px 5px rgba(0, 0, 0, 0.1),
    inset -2px -2px 5px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.3s;
}

.box_shadow:hover {
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.4),
    -8px -8px 20px rgba(255, 255, 255, 0.7),
    inset 3px 3px 7px rgba(0, 0, 0, 0.2),
    inset -3px -3px 7px rgba(255, 255, 255, 0.6);
}



.home-contect-div-download {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}

.home-contect-div-download h1 {
  text-align: center;
  font-size: 55px;
  font-weight: 900;
  color: #ff6900;
}

.home-contect-div-download button.yellow_button {
  font-size: 22px;
}

.home-contect-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: column;
}

.home-contect-div div {
  padding: 20px 40px;
  background-color: rgb(140 83 255);
}

.home-contect-div p {
  margin: 0;
  color: #fff;
  font-weight: 900;
}

.orange_btn{
    margin-left: 20px;
    margin-right: 20px;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #ff6900;
    color: #fff;
    font-weight: 900;
}
.div2{
  width: 90%;
}
@media (max-width: 768px) {

  .div2{
    width: 100%;
  }
.home-contect-div-download h1 {
  font-size: 40px;
}

  .mobile-only {
    display: block;
  }

  .home-page-hero .hero-content p {
    font-size: 32px;
  }

  .home-page-hero .hero-content {
    bottom: 15%;
    padding: 30px;
  }
}

.accordion-button:not(.collapsed) {
  background-color: #c4aef0;
  box-shadow: none;
  font-weight: 900;
}

.accordion-button:focus{
  box-shadow: none;
}

.accordion-flush>.accordion-item>.accordion-header .accordion-button.collapsed {
  background-color: #ffe4bc;
  font-weight: 900;
}